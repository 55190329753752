<template>
	<div class="v2-adopt-list">
		<div class="el-content v2al-header mb12">
			<div class="v2al-header-left" v-if="statistics">
				<div class="v2al-header-left-title">
					<h4>数据统计</h4>
					<div>
						<span class="time">更新时间：{{ new Date().toLocaleString() }}</span>
						<i class="ri-refresh-line ri-lg ri-top" @click="getAdoptTjStatistics()"></i>
					</div>
				</div>
				<div class="v2al-header-left-info">
					<div class="item">
						<p>{{ statistics.adopt_nums }}</p>
						<span>认养项目</span>
					</div>
					<div class="item">
						<p>{{ statistics.cycle}}</p>
						<span>认养周期</span>
					</div>
					<div class="item">
						<p>{{ statistics.adopt_order }}</p>
						<span>认养订单</span>
					</div>
					<div class="item">
						<p>{{ statistics.adopt_DeliveryTime_order }}</p>
						<span>待配送订单</span>
					</div>
				</div>
			</div>
			<div class="v2al-header-right">
				<h4 class="v2al-header-right-title">认养通知</h4>
				<template v-if="messageList.length >0">
					<div class="v2al-header-right-item" v-for="item in messageList" :key="item.id">
						<div class="content">{{ item.title }} </div>
						<router-link :to="{path:'/adopt/orderDetail',query:{order_id:item.to_id}}">
							<i class="ri-more-fill ri-lg"></i>
						</router-link>
					</div>
				</template>
			</div>
		</div>
		
		<div class="el-content v2al-list">
			<a-tabs v-model:activeKey="search.category_id" @change="getAdoptList(1,info.limit)">
			    <a-tab-pane key="" tab="全部"></a-tab-pane>
			    <a-tab-pane v-for="item in typeData" :key="item.id" :tab="item.name"></a-tab-pane>
			</a-tabs>
			<a-space class="mb12"> 
				
				<com-shop-search-select v-if="isShop == 0" @change="e=>search.shop_id = e"></com-shop-search-select>
				<a-input-search v-model:value="search.key" placeholder="请输入项目名称进行搜索" enter-button style="width: 300px;" @search="getLandList(1, info.limit)" />
				<router-link :to="{path:isShop == 0 ?'/adopt/edit':'/many_store/adopt/edit',query:{shop_id:search.shop_id}}">
					<a-button type="primary" v-has="{action:'/adopt/edit',plat:isShop}">
						<i class="ri-add-line ri-top"></i>新增项目
					</a-button>
				</router-link>
			</a-space>
			<div v-for="(item,index) in info.list" :key="index">
				<a-row class="v2al-list-item" >
					<a-col :span="6">
						<div class="v2al-list-info">
							<img class="cover" :src="item.cover" alt="">
							<div>
								<div class="adopt-name">{{ item.name}}</div>
								<span class="adopt-number">编号：{{ item.id }}</span>
								<div style="display: flex;">
									<a-rate v-model:value="item.favorable_num" disabled/>
								</div>
							</div>
						</div>
					</a-col>
					<a-col :span="3">
						<div class="v2al-list-num">
							<b>{{ item.all_numbers }}</b>
							<div>周期数</div>
						</div>
					</a-col>
					<a-col :span="3">
						<div class="v2al-list-num">
							<b> {{ item.view }} </b>
							<div>项目热度</div>
						</div>
					</a-col>
					<a-col :span="3">
						<div class="v2al-list-num">
							<b>{{ item.all_order_nums }}</b>
							<div>订单数量</div>
						</div>
					</a-col>
					<a-col :span="3">
						<div class="v2al-list-num">
							<b>{{ item.sales_volume }}</b>
							<div>销售总额</div>
						</div>
					</a-col>
					<a-col :span="6">
						<div class="v2al-list-btn">
							<a-space>
								<kd-button title="查看周期信息" type="success" icon="ri-calendar-check-line" @click="getProjectCycle(item.id)" v-has="{action:'wxapp_adopt_cycle_list',plat:isShop}"></kd-button>
								<kd-button title="添加周期信息" type="primary" icon="ri-add-line" @click="copyProject(item.id)" v-has="{action:'/adopt/edit',plat:isShop}"></kd-button>
								<router-link :to="{path:isShop == 0 ?'/adopt/edit':'/many_store/adopt/edit',query:{shop_id:search.shop_id,id:item.id}}">
									<kd-button title="编辑项目" 
										type="purple" 
										icon="ri-edit-box-line" 
										v-has="{action:'/adopt/edit',plat:isShop}">
									</kd-button>
								</router-link>
								
								<kd-button title="删除" type="danger" icon="ri-delete-bin-line" @click="delAdopt(item.id)" v-has="{action:'/adopt/edit',plat:isShop}"></kd-button>
								<a-dropdown>
									<kd-button title="更多信息" type="purple" icon="ri-more-fill" v-has="{action:'/adopt/edit',plat:isShop}"></kd-button>
								    <template #overlay>
										<a-menu>
											<a-menu-item @click="handleCommand('feature_'+item.id)">项目特点</a-menu-item>
											<a-menu-item @click="handleCommand('flow_'+item.id)">认养流程</a-menu-item>
											<a-menu-item @click="handleCommand('environment_'+item.id)">认养环境</a-menu-item>
											<a-menu-item @click="handleCommand('sort_'+item.id)">认养排名</a-menu-item>
											<a-menu-item @click="handleCommand('issue_'+item.id)">认养问题</a-menu-item>
										</a-menu>
								    </template>
								  </a-dropdown>
							</a-space>
						</div>
					</a-col>
				</a-row>
				<div class="v2al-list-cycle" v-if="show.cycle_list && cycle.adopt_id == item.id">
					<a-table :pagination="false" row-key="id" :data-source="cycle.list" :columns="[
						{title:'名称',dataIndex:'name',slots:{customRender:'name'}},
						{title:'周期',dataIndex:'numbers',slots:{customRender:'numbers'}},
						{title:'状态',dataIndex:'adopt_status',slots:{customRender:'adopt_status'}},
						{title:'认养数量',dataIndex:'rank'},
						{title:'单价',dataIndex:'rank'},
						{title:'认养天数',dataIndex:'rank'},
						{title:'上下架',dataIndex:'is_put_away',slots:{customRender:'is_put_away'}},
						{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
					]" >
						<template #name="{record}">
							<div class="v2al-list-info">
								<a-image
									style="width: 50px; height: 50px;margin-right: 12px;" 
									:src="record.cover" />
								<div>
									<div class="adopt-name">{{ record.name}}</div>
									<div class="adopt-number">编号：{{ record.id }}</div>
									<div class="adopt-number">品种：{{ record.varieties }}</div>
								</div>
							</div>
						</template>
						<template #numbers="{record}">
							<span>第{{record.numbers}}期</span>
						</template>
						<template #adopt_status="{record}">
							<a-dropdown>
								<a-tag :color="getCycleStatus(record.adopt_status,false)">{{getCycleStatus(record.adopt_status,true) }}</a-tag>
							    <template #overlay>
									<a-menu>
										<a-menu-item @click="changeProjectCycleStatus('1_'+record.id+'_'+record.copy_id)">报名中</a-menu-item>
										<a-menu-item @click="changeProjectCycleStatus('2_'+record.id+'_'+record.copy_id)">认养中</a-menu-item>
										<a-menu-item @click="changeProjectCycleStatus('3_'+record.id+'_'+record.copy_id)">配送中</a-menu-item>
										<a-menu-item @click="changeProjectCycleStatus('4_'+record.id+'_'+record.copy_id)">已完成</a-menu-item>
									</a-menu>
							    </template>
							  </a-dropdown>
						</template>
						<template #is_put_away="{record}">
							<a-tag
								style="cursor: pointer;"
								:color="record.is_put_away ==1 ?'#00CC66':'#999'"
								@click="changePutAway(record.is_put_away,record.id,record.copy_id)">
								{{ record.is_put_away ==1 ?'上架中':'已下架'}}
							</a-tag>
						</template>
						<template #action="{record}">
							<a-space>
								<router-link :to="{path: isShop == 0 ? '/adopt/edit':'/many_store/adopt/edit',query:{id:record.id,shop_id:search.shop_id}}">
									<kd-button 
										title="编辑项目" 
										type="purple" 
										icon="ri-edit-box-line" 
										v-has="{action:'/adopt/edit',plat:isShop}">
									</kd-button>
								</router-link>	
								<kd-button 
									title="认养码" 
									type="primary" 
									icon="ri-qr-code-line" 
									@click="showAdoptQrcode(record.id,record.qrcode)">
								</kd-button>
								<kd-button 
									title="删除" 
									type="danger" 
									icon="ri-delete-bin-line" 
									@click="delAdopt(record.id,record.copy_id)" 
									v-has="{action:'/adopt/edit',plat:isShop}">
								</kd-button>
								<a-dropdown>
									<kd-button 
										title="更多信息" 
										type="purple" 
										icon="ri-more-fill" 
										v-has="{action:'/adopt/edit',plat:isShop}">
									</kd-button>
								    <template #overlay>
										<a-menu>
											<a-menu-item @click="handleCommand('feature_'+record.id)">项目特点</a-menu-item>
											<a-menu-item @click="handleCommand('flow_'+record.id)">认养流程</a-menu-item>
											<a-menu-item @click="handleCommand('environment_'+record.id)">认养环境</a-menu-item>
											<a-menu-item @click="handleCommand('sort_'+record.id)">认养排名</a-menu-item>
											<a-menu-item @click="handleCommand('issue_'+record.id)">认养问题</a-menu-item>
										</a-menu>
								    </template>
								  </a-dropdown>
							</a-space>
						</template>
					</a-table>
				</div>
			</div>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getAdoptList(info.page,e)}"
					@change="(e)=>{getAdoptList(e,info.limit)}"
				/>
			</div>
		</div>

		<!-- 认养特点 -->
		<a-drawer title="项目特点设置" :width="900" :visible="show.feature" @close="show.feature = false" >
			<a-form :model="featureForm" >
				<a-form-item label="项目特点1" v-for="(item,index) in featureForm" :key="index">
					<div class="v2al-feature-item">
						<kd-img-select :src="item.img" @change="(url)=>{ item.img = url}"></kd-img-select>
						<a-input v-model:value="item.name" class="v2al-input" placeholder="特点名称" style="width: 300px;"></a-input>
						<a-input v-model:value="item.summary" class="v2al-input" placeholder="特点简介" style="width: 500px;"></a-input>
						<i class="ri-delete-back-2-fill ri-2x" style="margin-left: 12px;" @click="addOrDelFeature(index)"></i>
					</div>
				</a-form-item>
			</a-form>
			<div class="drawer-footer">
				<a-space>
					<a-button type="primary" @click="addOrDelFeature(-1)"><i class="ri-add-line ri-lg ri-top"></i>添加特点</a-button>
					<a-button type="primary" @click="saveFeatureForm">保存</a-button>
					<a-button style="margin-right: 8px" @click="show.feature = false">取消</a-button>
				</a-space>
			</div>
		</a-drawer>

		<!-- 认养流程 -->
		<a-drawer :width="900" :visible="show.flow" @close="show.flow = false" >
			<template #title>
				<span>认养流程设置
					<a-tooltip>
						<template #title>
							<div><a-image style="width: 200px;" :src="$util.getStaticSrc('adopt/explain-4.jpg')"/></div>
						</template>
						<i class="ri-information-fill"></i>
					</a-tooltip>
				</span>
			</template>
			<a-form :model="flowForm" >
				<a-form-item label="开启流程">
					<kd-switch :value="flowForm.status" @change="e=>flowForm.status = e" :active-value="1" :inactive-value="2"></kd-switch>
				</a-form-item>
				<template v-if="flowForm.status==1">
					<a-row :gutter="20" class="v2al-feature-flow" v-for="(item,index) in flowForm.flowinfo" :key="index">
						<a-col :span="12">
							<a-form-item label="流程标题">
								<a-input v-model:value="item.name" placeholder="请输入流程标题"></a-input>
							</a-form-item>
							<a-form-item label="流程说明">
								<a-input v-model:value="item.summary" type="textarea" placeholder="请输入流程说明"></a-input>
							</a-form-item>
							<a-form-item v-model:value="item.time" label="流程时间">
								<a-range-picker v-model:value="item.time" />
							</a-form-item>
						</a-col>
						<a-col :span="12">
							<a-form-item label="流程图标">
								<kd-img-select :src="item.img" @change="(url)=>{ item.img = url}"></kd-img-select>
							</a-form-item>
							<a-form-item label="流程示例图">
								<kd-img-select :mutil="true" :src="item.imgs" @change="(url)=>{ item.imgs = url}"></kd-img-select>
							</a-form-item>
						</a-col>
					</a-row>
				</template>
			</a-form>
			<div class="drawer-footer">
				<a-space>
					<a-button type="primary" @click="addOrDelFlow(-1)"><i class="ri-add-line ri-lg ri-top"></i>添加流程</a-button>
					<a-button type="primary" @click="saveFlowForm">保存</a-button>
					<a-button style="margin-right: 8px" @click="show.flow = false">取消</a-button>
				</a-space>
			</div>
		</a-drawer>
		
		<!-- 认养问题 -->
		<a-drawer :width="900" :visible="show.issue" @close="show.issue = false" >
			<template #title>
				<span>认养问题设置
					<a-tooltip>
						<template #title>
							<div><a-image style="width: 200px;" :src="$util.getStaticSrc('adopt/explain-3.jpg')"/></div>
						</template>
						<i class="ri-information-fill"></i>
					</a-tooltip>
				</span>
			</template>
			<a-form ref="issueForm" :model="flowForm" >
				<a-form-item label="开启问题">
					<kd-switch :value="flowForm.qa_status" @change="e=>flowForm.qa_status = e" :active-value="1" :inactive-value="2"></kd-switch>
				</a-form-item>
				<template v-if="flowForm.qa_status == 1">
					<a-form-item :label="'问题'+(index+1)" v-for="(item,index) in flowForm.qa_array" :key="index">
						<div style="display: flex;align-items: center;">
							<a-input v-model:value="item.ask_title" placeholder="请输入问题信息" style="width: 250px;"></a-input>
							<a-input v-model:value="item.asnwer" placeholder="请输入问题答案" style="width: 400px;margin: 0 12px;"></a-input>
							<i class="ri-delete-back-2-fill ri-2x" @click="addOrDelIssus(index)"></i>
						</div>
					</a-form-item>
				</template>
			</a-form>
			<div class="drawer-footer">
				<a-space>
					<a-button type="primary" @click="addOrDelIssus(-1)"><i class="ri-add-line ri-lg ri-top"></i>添加问题</a-button>
					<a-button type="primary" @click="saveIssueForm">保存</a-button>
					<a-button style="margin-right: 8px" @click="show.issue = false">取消</a-button>
				</a-space>
			</div>
		</a-drawer>
		
		<!-- 认养排名 -->
		<a-modal v-model:visible="show.sort" @ok="saveSortForm" width="600px" @cancel="show.sort = false">
			<template #title>
				<span>认养排名设置
					<a-tooltip>
						<template #title>
							<div><a-image style="width: 200px;" :src="$util.getStaticSrc('adopt/explain-2.jpg')"/></div>
						</template>
						<i class="ri-information-fill"></i>
					</a-tooltip>
				</span>
			</template>
			<a-form ref="typeForm" :model="flowForm" :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="开启认养排名">
					<kd-switch :value="flowForm.ranking_status" @change="e=>flowForm.ranking_status = e" :active-value="1" :inactive-value="2"></kd-switch>
				</a-form-item>
				<template v-if="flowForm.ranking_status == 1">
					<a-form-item label="认养排名数量">
						<a-input v-model:value="flowForm.ranking_num" addon-after="个"> </a-input>
					</a-form-item>
					<a-form-item label="认养排名说明" >
						<a-input v-model:value="flowForm.ranking_info"></a-input>
					</a-form-item>
				</template>
			</a-form>
		</a-modal>
		
		<!-- 认养环境设置 -->
		<a-drawer :width="900" :visible="show.environment" @close="show.environment = false" >
			<template #title>
				<span>认养环境设置
					<a-tooltip>
						<template #title>
							<div><a-image style="width: 200px;" :src="$util.getStaticSrc('adopt/explain-1.jpg')"/></div>
						</template>
						<i class="ri-information-fill"></i>
					</a-tooltip>
				</span>
			</template>
			<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="农场名称">
					<kd-switch :value="environmentForm.status" @change="e=>environmentForm.status = e" :active-value="1" :inactive-value="2"></kd-switch>
				</a-form-item>
				<template v-if="environmentForm.status==1">
					<a-form-item label="农场名称">
						<a-input v-model:value="environmentForm.name" placeholder="农场名称"></a-input>
					</a-form-item>
					<a-form-item label="农场主姓名">
						<a-input v-model:value="environmentForm.user_name" placeholder="农场主姓名"></a-input>
					</a-form-item>
					<a-form-item label="农场主头像">
						<kd-img-select :src="environmentForm.user_avatar" @change="(url)=>{ environmentForm.user_avatar = url}"></kd-img-select>
					</a-form-item>
					<a-form-item label="地址名称">
						<a-input v-model:value="environmentForm.area.address" placeholder="地址名称"></a-input>
					</a-form-item>
					<a-form-item label="选择区域">
						<span v-if="environmentForm.area.area">已绘制区域{{ environmentForm.area.area }}平方米</span>
						<div v-for="( item,index ) in environmentForm.area.point" :key="index">经度:{{item[0]}},纬度：{{ item[1] }}</div>
						<a-button type="primary" @click="show.map = true">选择区域</a-button>
					</a-form-item>
				</template>
			</a-form>
			<div class="drawer-footer">
				<a-space>
					<a-button type="primary" @click="saveEnvironmentForm">保存</a-button>
					<a-button style="margin-right: 8px" @click="show.environment = false">取消</a-button>
				</a-space>
			</div>
		</a-drawer>
		<a-modal v-model:visible="show.map" title="选择区域" :footer="null" width="80%">
			<div v-if="show.map">
				<kd-map-earth :is-satellite="true" :map-width="1200" :map-height="600" :mapValue="environmentForm.area.point" @getMapData="getMapArea">
				</kd-map-earth>
			</div>
		</a-modal>
		<a-modal title="认养二维码" v-model:visible="show.adopt_qrcode_show" :footer="null" width="300px" @cancel="show.adopt_qrcode_show = false">
			<div style="text-align:center">
				<p style="color: #999;font-size: 12px;" v-if="!show.adopt_qrcode">请重新编辑并保存商品信息以获取小程序码！</p>
				<div class="mb12">
					<a-image style="width: 200px; height: 200px" :src="show.adopt_qrcode" />
				</div>
				<a-button @click="downloadQrcode" type="primary">下载二维码</a-button>
			</div>
		</a-modal>
	</div>
</template>

<script>
import siteinfo from '@/util/global.js'
import kdMapEarth from "@/components/public/kd-map-earth.vue"
import comShopSearchSelect from '@/components/miniapp/com-shop-search-select.vue'
import { computed, reactive, toRefs } from 'vue'
import adoptModel from '@/api/adopt.js'
import common from '@/api/common.js'
export default{
	name:"page-adopt-list",
	components:{
		kdMapEarth,
		comShopSearchSelect
	},
	props:{
		isShop:{
			type:[String,Number],
			default:0
		}
	},
	setup(){
		const _d = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			search:{
				key:"",
				category_id:"",
				shop_id:"",
			},
			statistics:null,	//	统计信息
			typeData:[],
			messageList:[],		//消息列表
			category_id:'1',
			adopt_id:0,				//编辑项目id
			
			cycle:{
				list:[],
				adopt_id:0,
			},
			cycleList:[],
			featureForm:[],		//项目特点信息
			environmentForm:{
				status:2,
				name:"",
				user_name:"",
				user_avatar:"",
				area:{
					area:"",
					point:[],
					address:"",
					lat:"",
					lng:""
				},
			},
			flowForm:{
				status:2,
				ranking_status:2,
				ranking_num:"",
				ranking_info:"",
				qa_status:2,
				qa_array:[],
				flowinfo:[],
			},
			show:{
				cycle_list:false, 	//展示周期信息
				feature:false,		//项目特显
				flow:false,			//项目流程
				environment:false,	//项目环境
				sort:false,			//项目排名
				issue:false,		//项目问题
				device:false,		//项目设备
				admin:false,		//项目管理员
				map:false,
				adopt_qrcode_show:false,
				adopt_qrcode:"",	//认养码
			},
		})

		adoptModel.getAdoptStatistics(res=>_d.statistics = res)
		common.getNewMessge(1,5,{type:8},res=>_d.messageList = res.list)
		getAdoptList(1,_d.info.limit)
		
		function getAdoptList(page,limit){
			adoptModel.getAdoptList(page,limit,_d.search,res=>_d.info = {limit,...res})
		}

		function handleCommand(command){
			let _s = command.split("_")
			_d.adopt_id = _s[1]
			_d.show[_s[0]] = true
			if( _s[0] == 'feature' ){
				_d.featureForm = []
				getAdoptProjectFeatures();
			}
			if( _s[0] == 'flow' || _s[0] == 'issue'||_s[0] == 'sort' ){
				getAdoptionFlow();
			}
			
			if( _s[0] == 'environment' ){
				getAdoptArea()
			}
		}

		function addOrDelFeature(index){
			if( index <=-1 ){
				if( !_d.featureForm || _d.featureForm.length == 0 ) _d.featureForm = []
				_d.featureForm.push({
					img:"",
					name:"",
					summary:""
				})
				return
			}
			_d.featureForm.splice(index,1)
		}

		function getAdoptProjectFeatures(){
			adoptModel.getAdoptFeatures(_d.adopt_id,res=>_d.featureForm = res)
		}

		function getAdoptionFlow(){
			adoptModel.getFlowDetail(_d.adopt_id ,res=>_d.flowForm = res)
		}
		function addOrDelFlow(index){
			if( index <=-1 ){
				_d.flowForm.flowinfo.push({
					img:"",
					name:"",
					summary:"",
					time:[],
					imgs:[],
				})
				return
			}
			_d.flowForm.flowinfo.splice(index,1)
		}

		function addOrDelIssus(index){
			if( index <=-1 ){
				_d.flowForm.qa_array.push({
					ask_title:"",
					asnwer:"",
				})
				return
			}
			_d.flowForm.qa_array.splice(index,1)
		}
		
		function getAdoptArea(){
			adoptModel.getAdoptArea(_d.adopt_id,res=>_d.environmentForm = res)
		}
		
		function getMapArea(e){
			_d.environmentForm.area.area = e.area
			_d.environmentForm.area.point = e.point
			if( e.point.length > 0 ){
				_d.environmentForm.area.lng = e.point[0][0]
				_d.environmentForm.area.lat = e.point[0][1]
			}
			_d.show.map = false
		}

		function getAddress(e){
			_d.environmentForm.area.lat = e.latitude
			_d.environmentForm.area.lng = e.longitude
		}

		const saveFlowForm = ()=> adoptModel.saveAdoptFlow(_d.flowForm,_d.adopt_id,()=>{
			_d.show.flow = false
		})

		const saveFeatureForm = ()=> adoptModel.saveAdoptFeature(_d.featureForm,_d.adopt_id,()=>{
			_d.show.feature = false
		})

		const saveIssueForm = ()=>adoptModel.saveAdoptIssue(_d.flowForm,_d.adopt_id,()=>{
			_d.show.issue = false
		})

		const saveSortForm = ()=>adoptModel.saveAdoptSort(_d.flowForm,_d.adopt_id,()=>{
			_d.show.sort = false
		})
		const saveEnvironmentForm = ()=>adoptModel.saveAdoptEnvironment(_d.environmentForm,_d.adopt_id,()=>{
			_d.show.environment = false
		})

		const copyAdoption = (id)=>adoptModel.copyAdopt(id,()=>getProjectCycle(_d.cycle.adopt_id,true))

		function getProjectCycle(id,refresh){
			_d.cycle.list = []
			if( id == _d.cycle.adopt_id && refresh == false){
				_d.show.cycle_list = false
				_d.cycle.adopt_id = 0
			}else{
				_d.cycle.adopt_id = id
				adoptModel.getAdoptCycle(id,res=>{
					_d.cycle.list = res
					_d.show.cycle_list = true
				})
			}
		}

		function showAdoptQrcode(id,qrcode){
			_d.adopt_id = id
			_d.show.adopt_qrcode = qrcode
			_d.show.adopt_qrcode_show = true
		}

		function downloadQrcode(){
			let url = siteinfo.siteinfo.siteroot+'/api/admin/AdoptdownloadQrcode?token='+localStorage.getItem('token')+'&adopt_id='+_d.adopt_id;
			window.open(url);
		}

		const changePutAway = (status,id,copy_id)=>adoptModel.changeAdoptPutAway(id,status ==1 ?0 :1,()=>{
			getProjectCycle(copy_id)
		})

		const changeProjectCycleStatus = (e)=>{
			let _s = e.split('_')
			adoptModel.changeAdoptCycleStatus(_s[1],_s[0],()=>{
				getProjectCycle(_s[2])
			})
		}
		const delAdopt = (id,copy_id) => adoptModel.deleteAdopt(id,()=>{
			copy_id ? getProjectCycle(copy_id) : getAdoptList(_d.info.page,_d.info.limit)
		})

		const getCycleStatus = computed(()=>{
			return function(status,is_txt){
				if( status == 1 ) return is_txt ? '报名中':'#00CCCC'
				if( status == 2 ) return is_txt ? '认养中':'#00CCCC'
				if( status == 3 ) return is_txt ? '配送中':'#FF0066'
				if( status == 4 ) return is_txt ? '已完成':'#00CC66'
			}
		})
		const copyProject = (id)=> adoptModel.copyAdopt(id,()=>getProjectCycle(_d.cycle.adopt_id,true))
		return{
			...toRefs(_d),
			getAdoptList,
			handleCommand,
			addOrDelFeature,
			getAdoptProjectFeatures,
			saveFeatureForm,
			getAdoptionFlow,
			addOrDelFlow,
			addOrDelIssus,
			getAdoptArea,
			saveEnvironmentForm,
			getMapArea,
			getAddress,
			copyAdoption,
			getProjectCycle,
			changeProjectCycleStatus,
			changePutAway,
			showAdoptQrcode,
			downloadQrcode,
			delAdopt,
			getCycleStatus,
			copyProject,
			saveSortForm,
			saveFlowForm,
			saveIssueForm
		}
	},
}
</script>
<style lang="scss">
	.drawer-footer{
		position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        text-align: right;
        z-index: 1;
	}
	.v2-adopt-list{
		.v2al-header{
			display: flex;
			height: 160px;
			
			&-left{
				width: 60%;
				border: 1px solid #f4f4f4;
				
				&-title{
					padding: 0 24px;
					display: flex;
					height: 40px;
					align-items: center;
					justify-content: space-between;
					cursor: pointer;
					.time{
						color: #999;
						font-size: 15px;
					}
					i{
						color: #999;
						cursor: pointer;
					}
				}
				
				&-info{
					display: flex;
					align-items: center;
					padding: 0 24px;
					
					.item{
						width: 140px;
						text-align: center;
						
						p{
							font-weight: bold;
							font-size: 20px;
							margin: 8px 0;
						}
						span{
							color: #999;
							font-size: 15px;
						}
					}
				}
			}
			
			&-right{
				width: 37%;
				border: 1px solid #f4f4f4;
				margin-left: 3%;
				
				&-title{
					padding: 0 24px;
					margin: 8px 0 4px 0;
				}
				
				&-item{
					display: flex;
					align-items: center;
					height: 24px;
					padding: 0 24px;
					cursor: pointer;
					
					.content{
						width: 60%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						font-size: 14px;
						color: #666;
					}
					span{
						margin-right: 8px;
						font-size: 12px;
						color: #999;
					}
				}
			}
		}
	
		.v2al-list{
			
			&-add{
				position: relative;
				top: -40px;
				left: 92%;
			}
			
			&-search{
				position: relative;
				margin-bottom: 24px;
			}
			&-item{
				border: 1px solid #f4f4f4;
				padding: 12px;
				margin-bottom: 12px;
				cursor: pointer;
			}
			&-info{
				width: 100%;
				display: flex;
				.cover{
					width: 80px;
					height: 80px;
					margin-right: 12px;
				}
				.adopt-number{
					color: #999;
					font-size: 13px;
				}
				.star{
					display: flex;
					margin-top: 4px;
					align-items: center;
					i{
						margin-right: 2px;
						color: orange;
					}
				}
			}
			
			&-num{
				text-align: center;
				padding-top: 8px;
				b{
					font-size: 20px;
				}
				div{
					color: #999;
					font-size: 13px;
					margin-top: 6px;
				}
			}
			
			&-btn{
				width: 100%;
				display: flex;
				height:80px;
				align-items: center;
				justify-content: center;
				.v2al-btn{
					width: 30px;
					height: 30px;
					border-radius: 50%;
					text-align: center;
					line-height: 30px;
					color: #fff;
					margin-right: 12px;
					cursor: pointer;
				}
				.btn-one{
					background:rgba($color: #147afd, $alpha: .2);
					color: #147afd;
				}
				.btn-two{
					background:rgba($color: #ea3f0c, $alpha: .2);
					color: #ea3f0c;
				}
				.btn-three{
					background:rgba($color: #07d359, $alpha: .2);
					color: #07d359;
				}
				.btn-four{
					background:rgba($color: #a333ea, $alpha: .2);
					color: #a333ea;
				}
			}
			
			&-none{
				width: 100%;
				padding: 24px 0;
				text-align: center;
				color: #999;
			}
			
			&-cycle{
				padding-bottom: 24px;
			}
		}
	}
	
	.v2al-add{
		width: 100%;
		height: 32px;
		text-align: center;
		border: 1px dashed #999;
		line-height: 32px;
		cursor: pointer;
	}
	.v2al-feature{
		padding: 24px;
		overflow: hidden;
		overflow-y: auto;
		position: fixed;
		height: 60%;
		width: 65%;
		.ri-delete-back-2-fill{
			cursor: pointer;
		}
		
		&-flow{
			border: 1px solid #f4f4f4;
			padding: 12px 0;
			margin-bottom: 12px;
		}
	}
	
	.v2al-feature-footer{
		margin-left: 104px;
		padding-top: 24px;
		position: fixed;
		top: 80%;
		
	}
	.v2al-feature-item{
		display: flex;
		.v2al-input{
			height: 32px;
			margin: 4px 6px 0 6px;
		}
	}
	
	.ri-delete-back-2-fill{
		cursor: pointer;
	}
</style>
